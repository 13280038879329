import { getFetch, postFetch, PAY_AT_PICKUP_SKU } from "./Util";
import { shortDisplayTime, anonymizeEmail, anonymizePhone } from "./TypedUtil";
import { restaurantIdToSlug } from './o2';
import { gtin } from "cdigit";

// Magic port of our print agent running on the local machine.
// For now, we only allow displays with locally running print agent to print.
const PRINT_AGENT_URL = "http://localhost:27245";

// TODO(kku): Don't hardcode printer MAC

// List of printer addresses, in fallback order.
const ORDER_PRINTERS = [
  // MUNBYN ITPP047 primary
  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    ip: "192.168.1.245",
  },
  // MUNBYN ITPP047 primary MAC
  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    mac: "00:57:49:E5:72:7B",
  },
  // MUNBYN ITPP047 backup
  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    ip: "192.168.1.5",
  },
  // MUNBYN ITPP047 backup MAC
  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    mac: "00:44:d6:73:2b:81",
  },

  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    ip: "192.168.1.246",
  },
  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    mac: "00:4A:0E:FC:DF:EE"
  },

  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    ip: "192.168.1.247",
  },
  {
    type: "GENERIC_ESC_POS",
    port: 9100,
    mac: "00:4D:0D:DF:E2:9B"
  },
];

const LABEL_PRINTERS = [
  // o2-cupertino-brother
  {
    type: "BROTHER_QL_RASTER",
    port: 9100,
    ip: "192.168.1.185",
  },
  // o2-palo-alto-brother-2
  {
    type: "BROTHER_QL_RASTER",
    port: 9100,
    ip: "192.168.1.11",
  },
  // o2-palo-alto-brother-1
  {
    type: "BROTHER_QL_RASTER",
    port: 9100,
    ip: "192.168.1.29",
  },
  // o2-cupertino-brother
  {
    type: "BROTHER_QL_RASTER",
    port: 9100,
    mac: "e8:6f:38:5b:04:4b",
  },
  // o2-palo-alto-brother-2
  {
    type: "BROTHER_QL_RASTER",
    port: 9100,
    mac: "4c:d5:77:b5:b9:11",
  },
  // o2-palo-alto-brother-1
  {
    type: "BROTHER_QL_RASTER",
    port: 9100,
    mac: "B4:B5:B6:A3:83:E2",
  },
];

export const scanPrinters = () => {
  postFetch(
    PRINT_AGENT_URL + "/v1/scan",
    {},
    (resp, date, etag) => {},
    (err) => {},
  );
};

export const getPrinters = (callback) => {
  getFetch(
    `${PRINT_AGENT_URL}/v1/printers`,
    (printers) => {callback(printers, null)},
    (err) => {callback(null, err)},
  );
};

const isCloverOrder = (order) => {
  return order.id.startsWith("odr_clover");
};

const isDeliveryOrder = (order) => {
  if (order.pos_order_type) {
    // TODO(kku): handle other delivery types
    const t = order.pos_order_type.toLowerCase();
    return t === "doordash" || t === "ubereats" || t === "uber eats";
  }
  return false;
};

export const printOnStartOrder = (order, errCallback) => {
  // Don't auto-print orders from clover - some items might be delayed.
  // Current workflow at restaurant is to use the ticket printed by clover
  // to fulfill an order.
  if (!isCloverOrder(order)) {
    printOrder(order, () => {}, errCallback);
  }
};

export const printOnOrderDone = (order, errCallback) => {
  // Print point QR code for clover orders since items might arrive late,
  // so the total/points is only correct when order is done.
  // Point card for delivery order is placed in bag, so pring separately.
  if (isCloverOrder(order) || isDeliveryOrder(order)) {
    if (order.point_credit) {
      printPointCard(order, () => {}, errCallback);
    }
  }
};

export const printPointCard = (order, successCallback, errCallback) => {
  const req = printPointCardRequest(order);
  postFetch(
    PRINT_AGENT_URL + "/v2/print_multiple",
    { requests: [req] },
    (resp, date, etag) => {
      successCallback();
    },
    (err) => {errCallback(err)},
  );
};

export const reprintItemLabel = (order, item, successCallback, errCallback) => {
  const req = printItemRequest(order, item.attributes, "(reprint)");
  postFetch(
    PRINT_AGENT_URL + "/v2/print_multiple",
    { requests: [req] },
    (resp, date, etag) => {
      successCallback();
    },
    (err) => {errCallback(err)},
  );
};

export const printOrder = (order, successCallback, errCallback) => {
  // Print the entire order.
  var reqs = [
    printOrderRequest(order),
    ...createPrintItemRequests(order, false),
  ];

  postFetch(
    PRINT_AGENT_URL + "/v2/print_multiple",
    { requests: reqs },
    (resp, date, etag) => {
      successCallback();
    },
    (err) => {errCallback(err)},
  );
};

// Print a label for every item that usually don't print label (e.g. bento)
export const printNonLabelItemInOrder = (order, successCallback, errCallback) => {
  postFetch(
    PRINT_AGENT_URL + "/v2/print_multiple",
    { requests: createPrintItemRequests(order, true) },
    (resp, date, etag) => {
      successCallback();
    },
    (err) => {errCallback(err)},
  );
};

export const createPrintItemRequests = (order, invertLabelCondition) => {
  var items_to_print = [];
  order.items.forEach((item) => {
    const printLabel = invertLabelCondition ? !item.print_label : item.print_label;

    if (item.attributes != null && printLabel) {
      let count = 1;
      if (item.hasOwnProperty('qty')) {
        count = item['qty'];
      }
      for (let i = 0; i < count; i++) {
        items_to_print.push(item);
      }
    }
  });

  var result = [];
  items_to_print.forEach((item, i) => {
    const suffix = "(" + (i + 1).toString() + "/" + items_to_print.length.toString() + ")";
    result.push(printItemRequest(order, item.attributes, suffix));
  })
  return result;
};

const formatExpireTime = (pointCredit) => {
  if (pointCredit && pointCredit.expire_time) {
    const expireTime = new Date(pointCredit.expire_time)
        .toLocaleDateString("en-ZA", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    return [
      {
          "data": "\n",
          "large_font": true,
      },
      {
        "data": `Points will expire on ${expireTime}`,
        "align": "CENTER",
      },
    ];
  }
  return [];
};

export const printPointCardRequest = (order) => {
  var lines = [];

  lines.push({
    "data": order.pos_order_name ? order.pos_order_name : order.pos_order_id,
    "align": "CENTER",
    "large_font": true,
  });
  lines.push({
    "data": order.pos_order_type,
    "align": "CENTER",
    "large_font": true,
  });
  lines.push({
    "data": "",
    "large_font": true,
  });
  // TODO(kku): Don't hardcode restaurant name on point card.
  lines.push({
    "data": "O2 Valley Loyalty Points",
    "align": "CENTER",
    "large_font": true,
  });
  lines.push({
    "data": "",
    "large_font": true,
  });

  if (order.point_credit) {
    lines.push({
      "data": `You earned ${order.point_credit.amount} points with this order!`,
      "align": "CENTER",
    });

    lines.push({
      "data": "",
      "align": "CENTER",
    });

    lines.push({
      "data": `You can use points to redeem for food and drinks on o2-valley.com`,
      "align": "CENTER",
    });

    lines.push({
      "data": "\n",
      "align": "CENTER",
    });

    // If the customer was not logged in, print QR code for them to claim their
    // points.
    lines.push({
      "data": "Scan the QR code to claim your points!",
      "align": "CENTER",
    });

    // TODO(kku): don't hardcode qr code address
    lines.push({
      "data": `https://order.o2-valley.com/${restaurantIdToSlug[order.restaurant_id]}/claim/${order.point_credit.id}`,
      "align": "CENTER",
      "qr_code": true,
    });

    lines = lines.concat(formatExpireTime(order.point_credit));
  }

  lines.push({
    "data": "\n",
    "large_font": true,
  });

  if (order.point_credit_id) {
    lines.push({
      "data": `Credits ID: ${order.point_credit_id}`,
    });
  }

  lines.push({
    "data": order.id + "\n",
  });
  lines.push({
    // Use South Africa locale to print 24hr time.
    "data": "Print Time: " + new Date().toLocaleString("en-ZA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })+ "\n",
  });

  // Create enough empty lines for a clean cut.
  lines.push({
    "data": "\n\n\n\n\n\n",
  });

  // print.Request https://github.com/TicketRails/print/blob/335bc106bbbd5c5abbfd60a1ac502c5b340c65d0/request.go#L11
  return {
    "printer_type": "GENERIC_ESC_POS",
    "printer_addresses": ORDER_PRINTERS,
    "lines": lines,
  };
};

// Returns data for UPC-A barcode.
export function createPayAtPickupBarcodeData(order): string | null {
  const total = order.pretax_price ?? 0;
  if (order.paid || total === 0) {
    return null;
  }

  if (total > 99999) {
    // We can only have up to 5 digit price ($999.99) in barcode. We don't print
    // barcode if price is too high, but we'll still show the order is UNPAID on
    // the receipt so clerk can figure out how to charge.
    return null;
  }

  return gtin.generate(`2${PAY_AT_PICKUP_SKU}${total.toString().padStart(5, '0')}`);
}

export const printOrderRequest = (order) => {
  // Group items by attribute id.
  var item_groups = {}; // map item attribute id => {count, attributes}
  order.items.forEach((item) => {
    if (item.attributes) {
      let new_count = 1;
      if (item.hasOwnProperty('qty')) {
        new_count = item['qty'];
      }

      if (item.attributes.id in item_groups) {
        item_groups[item.attributes.id].count += new_count;
        item_groups[item.attributes.id].redeemed |= item.redeemed;
      } else {
        item_groups[item.attributes.id] = {
          "count": new_count,
          "attributes": item.attributes,
          "print_label": item.print_label,
          "redeemed": item.redeemed,
        };
      }
    }
  });


  var lines = [];
  lines.push({
    // Some spacing from the top of page for clipping to physical ticket rails.
    "data": "\n\n\n\n\n\n\n\n\n",
    "align": "CENTER",
    "large_font": true,
  });

  if (!order.paid) {
    lines.push({
      "data": "*** UNPAID ***",
      "align": "CENTER",
      "large_font": true,
    });

    const barcode = createPayAtPickupBarcodeData(order);
    if (barcode != null) {
      lines.push({
        "data": "\n",
        "align": "CENTER",
        "large_font": true,
      });

      lines.push({
        "data": barcode,
        "align": "CENTER",
        "large_font": true,
        "barcode_type": "UPC-A",
      });

      lines.push({
        "data": "\n",
        "align": "CENTER",
        "large_font": true,
      });
    }
  }

  lines.push({
    "data": order.pos_order_name ? order.pos_order_name : order.pos_order_id,
    "align": "CENTER",
    "large_font": true,
  });
  lines.push({
    "data": order.pos_order_type,
    "align": "CENTER",
    "large_font": true,
  });
  lines.push({
    "data": "",
    "large_font": true,
  });

  if (order.customer) {
    // Print customer name up top, additional info on the bottom.
    lines.push({
      "data": "Customer: " + order.customer.name,
      "large_font": true,
    });
  }

  if (order.estimated_pickup_time) {
    try {
      var pickup = new Date(order.estimated_pickup_time);
      lines.push({
        "data": "Pickup: " + shortDisplayTime(pickup),
        "large_font": true,
      });
    } catch (err) {
      // Give up
      console.log("Failed to parse estimated pickup time", order.estimated_pickup_time, err)
    }
  }
  
  lines.push({
    "data": "",
    "large_font": true,
  });

  lines.push({
    "data": order.notes + "\n",
    "large_font": true,
  });

  var get_group_name = function(group) {
    return group.attributes.alternate_name ? group.attributes.alternate_name : group.attributes.name;
  };

  var groups = Object.values(item_groups);
  groups.sort((g1, g2) => {
    // Put items that are printed on separate labels last.
    if (g1.print_label && !g2.print_label) {
      return 1;
    } else if (!g1.print_label && g2.print_label) {
      return -1;
    }

    // Sort by name.
    return get_group_name(g1).localeCompare(get_group_name(g2));
  });

  groups.forEach((group) => {
    const redeemed = group.redeemed ? " [R]" : "";

    lines.push({
      "data": group.count.toString() + " " + get_group_name(group) + redeemed,
      "large_font": true,
    });
    
    if (group.attributes.modifications) {
      group.attributes.modifications.forEach((mod) => {
        lines.push({
          "data": mod,
          "align": "RIGHT",
          "large_font": true,
        });
      });
    }

    if (group.attributes.notes) {
      lines.push({
        "data": group.attributes.notes + "\n",
        "large_font": true,
      });
    }
  });

  // For delivery orders, print point card separately to place in bag.
  if (order.point_credit && !isDeliveryOrder(order)) {
    lines.push({
      "data": "\n",
      "large_font": true,
    });

    // If the customer was not logged in, print QR code for them to claim their
    // points.
    if (order.point_credit.claim_customer_id) {
      lines.push({
        "data": `${order.point_credit.amount} points have been added to your account!`,
        "align": "CENTER",
      });

      lines = lines.concat(formatExpireTime(order.point_credit));
    } else {
      lines.push({
        "data": `You earned ${order.point_credit.amount} points with this order!`,
        "align": "CENTER",
      });

      lines.push({
        "data": "",
        "align": "CENTER",
      });

      lines.push({
        "data": `You can use points to redeem for food and drinks on o2-valley.com`,
        "align": "CENTER",
      });

      lines.push({
        "data": "\n",
        "align": "CENTER",
      });

      lines.push({
        "data": "Scan the QR code to claim your points!",
        "align": "CENTER",
      });

      // TODO(kku): don't hardcode qr code address
      lines.push({
        "data": `https://order.o2-valley.com/${restaurantIdToSlug[order.restaurant_id]}/claim/${order.point_credit.id}`,
        "align": "CENTER",
        "qr_code": true,
      });

      lines = lines.concat(formatExpireTime(order.point_credit));
    }
  }

  lines.push({
    "data": "\n",
    "large_font": true,
  });

  if (order.customer) {
    lines.push({
      "data": "Customer Email: " + anonymizeEmail(order.customer.email ?? ""),
    });
    lines.push({
      "data": "Customer Phone: " + anonymizePhone(order.customer.phone ?? ""),
    });
  }

  if (order.point_credit_id && !order.customer_id) {
    lines.push({
      "data": `Credits ID: ${order.point_credit_id}`,
    });
  }

  lines.push({
    "data": order.id + "\n",
  });
  lines.push({
    // Use South Africa locale to print 24hr time.
    "data": "Print Time: " + new Date().toLocaleString("en-ZA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })+ "\n",
  });

  if (!order.paid) {
    lines.push({
      "data": "\n\n*** UNPAID ***",
      "align": "CENTER",
      "large_font": true,
    });
  }

  // Create enough empty lines for a clean cut.
  lines.push({
    "data": "\n\n\n\n\n\n\n\n",
  });

  // print.Request https://github.com/TicketRails/print/blob/335bc106bbbd5c5abbfd60a1ac502c5b340c65d0/request.go#L11
  return {
    "printer_type": "GENERIC_ESC_POS",
    "printer_addresses": ORDER_PRINTERS,
    "lines": lines,
  };
};

// Prints an item label
const printItemRequest = (order, item_attrs, suffix) => {
  var order_name = order.pos_order_name ? order.pos_order_name : order.pos_order_id;
  var lines = [
    {
      "data": order_name + " - " + order.pos_order_type + " " + suffix,
    },
    {
      "data": item_attrs.alternate_name ? item_attrs.alternate_name : item_attrs.name,
    },
  ];

  if (item_attrs.modifications) {
    var mods = item_attrs.modifications.join();
    if (mods) {
      lines.push({
        "data": mods,
      });
    }
  }

  if (item_attrs.notes) {
    lines.push({
      "data": item_attrs.notes,
    });
  }
  
  // print.Request https://github.com/TicketRails/print/blob/335bc106bbbd5c5abbfd60a1ac502c5b340c65d0/request.go#L11
  return {
    "printer_type": "BROTHER_QL_RASTER",
    "printer_addresses": LABEL_PRINTERS,
    "lines": lines,
    // TODO: figure out if rotate actually works. Seems broken for new print
    // agent (digest 082ce579fb2c on dockerhub)
    // "rotate": 180,
  };
};
